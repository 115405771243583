const COMPETITOR_NAMES = {
  VIATOR: 'viator',
  MUSEMENT: 'musement',
  KLOOK: 'klook',
  CIVITATIS: 'civitatis',
  TICKETBAR: 'ticketbar',
  TIQETS: 'tiqets',
  MEDIAVINE: 'mediavine',
};

export default COMPETITOR_NAMES;
