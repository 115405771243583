import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class CivitatisDeeplinksScraper extends Scraper {
  static CIVITATIS_LINK_QUERY = `a[href*="www.civitatis.com"]`;

  constructor() {
    super(
      'CIVITATIS-Deeplinks',
      CivitatisDeeplinksScraper.CIVITATIS_LINK_QUERY,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const deeplink = {
        name: COMPETITOR_NAMES.CIVITATIS,
        url: item.getAttribute('href'),
        text: item.textContent?.trim(),
      } as CompetitorDeeplinks;

      payload.competitor_deeplinks = payload.competitor_deeplinks || [];
      payload.competitor_deeplinks.push(deeplink);
    });
  }
}

export default CivitatisDeeplinksScraper;
