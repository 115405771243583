import tasks from '../tasks';
import history from 'history-events';

import Task from 'tasks/Task';
import PartnerEvent from './PartnerEvent';
import { BaseWebEvent } from '@getyourguide/analytics-events-js-sdk';
// @ts-ignore
import { PartnerAnalyzerPageRequestProperties } from '../../openapi/analytics-events/model/partner-analyzer-page-request-properties';
// @ts-ignore
import { WidgetAttributes as WidgetAttributesV2 } from '../../openapi/analytics-events/model/widget-attributes';
// @ts-ignore
import { GeoLocation as GeoLocationV2 } from '../../openapi/analytics-events/model/geo-location';
// @ts-ignore
import { WidgetParameters as WidgetParametersV2 } from '../../openapi/analytics-events/model/widget-parameters';

const EVENT_NAME: string = 'PartnerAnalyzerPageRequest';

export default class PartnerAnalyzerPageRequestEvent extends PartnerEvent<
  PartnerAnalyzerPageRequestClean
> {
  private _visitorId: string = '';
  private _sessionId: string = '';

  public get visitorId(): string {
    return this._visitorId;
  }
  public set visitorId(v: string) {
    this._visitorId = v;
  }
  public get sessionId(): string {
    return this._sessionId;
  }
  public set sessionId(v: string) {
    this._sessionId = v;
  }

  get collectorUrl(): string {
    return (
      this.baseUrl + '/track/PartnerAnalyzerPageRequest?format=json&strict=true'
    );
  }

  cleanupDataRepository() {
    delete this.dataRepository.competitor_deeplinks;
    delete this.dataRepository.competitor_widgets;

    delete this.dataRepository.deeplinks;
    delete this.dataRepository.widgets;
  }

  /**
   * AnalyzePage
   * Starts the process of crawling the page
   * @param document
   */
  async collectData(document: Document) {
    await Promise.all(
      tasks.map(CurrentTask => {
        const task: Task = new CurrentTask();
        return task.start({
          document,
          payload: this.dataRepository,
          visitorId: this.visitorId,
          sessionId: this.sessionId,
        });
      })
    );
  }

  onNewPageEvent() {
    if (!this.dataRepository.user || !this.dataRepository.header) {
      console.log("PA: Data hasn't been collected yet, skipping event");
      return;
    }

    // Note: if the browser is able to cache the page, `event.persisted`
    // is `true`, and the state is frozen rather than terminated.
    const payloadV2 = this.getEventV2Payload();
    try {
      // send OpenApi event v2
      this.sendEventV2(payloadV2, EVENT_NAME);

      this.cleanupDataRepository();
    } catch (error) {
      // Silently fail
      console.error('PA ERROR', error);
    }
  }

  addEventListeners() {
    const createEventListener = (eventName: string) => {
      window.addEventListener(eventName, this.onNewPageEvent.bind(this), {
        capture: true,
      });
    };

    // Setup listener for page unload event
    // https://developers.google.com/web/updates/2018/07/page-lifecycle-api
    const terminationEvent = 'onpagehide' in window ? 'pagehide' : 'unload';
    createEventListener(terminationEvent);

    if (history.isHistorySupported()) createEventListener('changestate');
  }

  /**
   * Add a post message listener to interecept events coming from the
   * widget's iframe in order to syncronyze both session and visitor IDs.
   */
  addPostMessageListener() {
    window.addEventListener(
      'message',
      event => {
        const { sessionId = null } = event.data;
        this.sessionId = sessionId;
      },
      {
        once: true,
        passive: true,
        capture: true,
      }
    );
  }

  start(): void {
    this.addEventListeners();
    this.addPostMessageListener();

    /**
     * Start analyzing the page once the document is ready
     */
    setInterval(() => {
      if (this.document.readyState === 'complete') {
        try {
          this.collectData(document);
        } catch (error) {
          // Sentry.captureException(error);
        }
      }
    }, 1000);
  }

  private getEventV2Payload() {
    const widgetsV2 = this.getWidgetsV2();
    const eventData: Partial<BaseWebEvent> &
      PartnerAnalyzerPageRequestProperties = {
      customer_id: this.dataRepository.user.customer_id,
      partner_hash_code: this.dataRepository.header.partner_hash_code,
      partner_id: this.dataRepository.header.partner_id,
      page: this.dataRepository.page,
      widgets: widgetsV2,
      deeplinks: this.dataRepository.deeplinks,
      competitor_widgets: this.dataRepository.competitor_widgets,
      competitor_deeplinks: this.dataRepository.competitor_deeplinks,
      version: this.dataRepository.version,
      script_origin: this.dataRepository.script_origin
        ? this.dataRepository.script_origin.toString()
        : '',
    };
    return eventData;
  }

  private getWidgetV2Parameters(widget: WidgetAttributes) {
    const geoLocation =
      widget.widget_parameters &&
      this.getGeoLocationIfPresent(widget.widget_parameters.geo_location);
    const widgetParameters: WidgetParametersV2 = widget.widget_parameters && {
      widget_id: widget.widget_parameters.widget_id,
      campaign: this.getParameterIfPresent(widget.widget_parameters.campaign),
      currency: this.getParameterIfPresent(widget.widget_parameters.currency),
      excluded_tour_ids: this.getParameterIfPresent(
        widget.widget_parameters.excluded_tour_ids
      ),
      iata: this.getParameterIfPresent(widget.widget_parameters.iata),
      location_id: this.getParameterIfPresent(
        // @ts-expect-error: rendering_time is not defined in thrift event
        widget.widget_parameters.location_id
      ),
      locale_code: this.getParameterIfPresent(
        widget.widget_parameters.locale_code
      ),
      number_of_items: this.getParameterIfPresent(
        widget.widget_parameters.number_of_items
      ),
      partner_hash_code: this.getParameterIfPresent(
        widget.widget_parameters.partner_id
      ),
      query: this.getParameterIfPresent(widget.widget_parameters.query),
      version: this.getParameterIfPresent(widget.widget_parameters.version),
      release_version: this.getParameterIfPresent(
        widget.widget_parameters.release_version
      ),
      format: this.getParameterIfPresent(widget.widget_parameters.format),
      tour_ids: this.getParameterIfPresent(widget.widget_parameters.tour_ids),
    };
    if (geoLocation) {
      widgetParameters.geo_location = geoLocation;
    }
    return widgetParameters;
  }

  private getGeoLocationIfPresent(geoLocation: GeoLocation) {
    if (geoLocation.latitude && geoLocation.longitude) {
      const glv2: GeoLocationV2 = {
        latitude: geoLocation.latitude,
        longitude: geoLocation.longitude,
      };
      return glv2;
    }
    return undefined;
  }

  private getParameterIfPresent(parameter: any) {
    return parameter ? parameter : undefined;
  }

  private getWidgetsV2() {
    const widgetsV2 = new Array<WidgetAttributesV2>();
    this.dataRepository.widgets &&
      this.dataRepository.widgets.forEach(widget => {
        const widgetParameters = this.getWidgetV2Parameters(widget);
        const widgetV2: WidgetAttributesV2 = {
          widget_parameters: widgetParameters,
          x: widget.x,
          y: widget.y,
          width: widget.width,
          height: widget.height,
          type: widget.type,
          // @ts-expect-error: rendering_time is not defined in thrift event
          rendering_time: widget.rendering_time,
        };
        widgetsV2.push(widgetV2);
      });
    return widgetsV2;
  }
}
