import { default as PartnerAnalyzerPageRequestEvent } from './PartnerAnalyzerPageRequestEvent';
import { default as PartnerDeeplinkClickEvent } from './PartnerDeeplinkClickEvent';
import { default as UIClickEvent } from './UIClickEvent';
import { default as UIShowEvent } from './UIShowEvent';
import { default as UIEnteredDisplayPort } from './UIEnteredDisplayPort';

// ticketing v2 events
import { default as TicketingWebUIClickEvent } from './TicketingWebUIClickEvent';
import { default as TicketingWebPageViewEvent } from './TicketingWebPageViewEvent';
import { default as TicketingWebLinkVisibleEvent } from './TicketingWebLinkVisibleEvent';
import { default as TicketingWebInitializedEvent } from './TicketingWebInitializedEvent';
import { default as TicketingWebAddToCartSuccessEvent } from './TicketingWebAddToCartSuccessEvent';
import { default as TicketingWebNoAvailabilityEvent } from './TicketingWebNoAvailabilityEvent';

export default [
  PartnerAnalyzerPageRequestEvent,
  PartnerDeeplinkClickEvent,
  UIClickEvent,
  UIShowEvent,
  UIEnteredDisplayPort,
  TicketingWebUIClickEvent,
  TicketingWebPageViewEvent,
  TicketingWebLinkVisibleEvent,
  TicketingWebInitializedEvent,
  TicketingWebAddToCartSuccessEvent,
  TicketingWebNoAvailabilityEvent,
];
