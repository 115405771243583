// extra fallback added for browsers that block setting local storage
export function setLocalStorage(key: string, value: string) {
  try {
    if (value) localStorage.setItem(key, value);
    window._GYG.localStorage = { ...window._GYG.localStorage, [key]: value };
  } catch (error) {
    window._GYG.localStorage = { ...window._GYG.localStorage, [key]: value };
  }
}

export function getLocalStorage(key: string) {
  try {
    if (localStorage.getItem(key)) return localStorage.getItem(key);
  } catch (error) {
    if (window._GYG.localStorage) return window._GYG.localStorage[key];
  }
  return null;
}

export function removeLocalStorage(key: string) {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    if (window._GYG.localStorage) delete window._GYG.localStorage[key];
  }
}
