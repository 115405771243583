import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class KlookWidgetsScraper extends Scraper {
  static KLOOK_LINK_QUERY = `iframe[src*="affiliate.klook.com"]`;

  constructor() {
    super(
      'KLOOK-CompetitorWidgets',
      KlookWidgetsScraper.KLOOK_LINK_QUERY,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const widget = {
        name: COMPETITOR_NAMES.KLOOK,
        url: item.getAttribute('src'),
      } as CompetitorWidgets;

      payload.competitor_widgets = payload.competitor_widgets || [];
      payload.competitor_widgets.push(widget);
    });
    return payload;
  }
}

export default KlookWidgetsScraper;
