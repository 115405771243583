import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class TiqetsWidgetsScraper extends Scraper {
  static TIQETS_WIDGET = `iframe[src*="www.tiqets.com/widgets"]`;

  constructor() {
    super(
      'Tiqets-CompetitorWidgets',
      TiqetsWidgetsScraper.TIQETS_WIDGET,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const widget = {
        name: COMPETITOR_NAMES.TIQETS,
        url: item.getAttribute('src'),
      } as CompetitorWidgets;

      payload.competitor_widgets = payload.competitor_widgets || [];
      payload.competitor_widgets.push(widget);
    });
    return payload;
  }
}

export default TiqetsWidgetsScraper;
