import { COLLECTOR_BASE_URL } from '../constants';
import 'whatwg-fetch';
import { BaseWebEvent } from '@getyourguide/analytics-events-js-sdk';
import { AnalyticsWebEventConfig } from './logger/analytics-web-event-config';
import { logAnalyticsWebEvent } from './logger/analytics-events-logger';

abstract class PartnerEvent<T> {
  abstract readonly collectorUrl: string;
  document: Document;
  dataRepository: DataRepository;
  protected baseUrl: string;

  constructor(
    dataRepository: DataRepository,
    document: Document,
    baseUrl: string = COLLECTOR_BASE_URL
  ) {
    this.dataRepository = dataRepository;
    this.document = document;
    this.baseUrl = baseUrl;
  }

  abstract start(): void;

  sendEvent(payload: T): void {
    window.fetch(this.collectorUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'text/plain',
      },
      body: JSON.stringify(payload),
      keepalive: true,
    });
  }

  sendEventV2(eventData: Partial<BaseWebEvent>, eventName: string): void {
    const loggerConfig: AnalyticsWebEventConfig = {
      collector_url: this.baseUrl,
      version: this.dataRepository.version,
      platform: this.dataRepository.header?.platform,
      // As agreed with analytics-migration-support-team, view_id will be sent as an empty string as at the moment,
      // we do not have the concept of view_id on our events.
      view_id: '',
      container_name: this.dataRepository.container_name,
      userAgent: this.dataRepository.header?.user_agent,
      domain: this.document.domain,
      url: this.document.URL,
      referrer_url: this.dataRepository.header?.referrer_url,
      visitor_id: this.dataRepository.user?.visitor_id,
      sent_by: this.dataRepository.event_properties?.sent_by,
    };
    logAnalyticsWebEvent(loggerConfig, eventData, eventName);
  }
}

export default PartnerEvent;
