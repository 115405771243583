import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebInitialized } from '../../openapi/analytics-events/model/ticketing-web-initialized';

const EVENT_NAME: string = 'TicketingWebInitialized';

export default class TicketingWebInitializedEvent extends PartnerEvent<
  TicketingWebInitialized
> {
  static NAMESPACE = 'cc34b8d9-1676-433b-8785-17712b04b97b'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebInitialized }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
