import GYGWidgetScraper from './GYGWidgetScraper';
import CivitatisWidgetsScraper from './CivitatisWidgetsScraper';
import KlookWidgetsScraper from './KlookWidgetsScraper';
import TicketbarWidgetsScraper from './TicketbarWidgetsScraper';
import TiqetsWidgetsScraper from './TiqetsWidgetsScraper';
import ViatorWidgetsScraper from './ViatorWidgetsScraper';
import MediavineWidgetsScraper from './MediavineWidgetsScraper';

export default [
  GYGWidgetScraper,
  CivitatisWidgetsScraper,
  KlookWidgetsScraper,
  TicketbarWidgetsScraper,
  TiqetsWidgetsScraper,
  ViatorWidgetsScraper,
  MediavineWidgetsScraper,
];
