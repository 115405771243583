import PartnerEvent from './PartnerEvent';
import { v5 as uuidv5 } from 'uuid';
import { removeDeeplinkQueryString } from '../helpers/url';
import { BaseWebEvent } from '@getyourguide/analytics-events-js-sdk';

// @ts-ignore
import { PartnerDeeplinkClickProperties } from '../../openapi/analytics-events/model/partner-deeplink-click-properties';

const EVENT_NAME: string = 'PartnerDeeplinkClick';

export default class PartnerDeeplinkClickEvent extends PartnerEvent<
  PartnerDeeplinkClick
> {
  static NAMESPACE = '6ba7b811-9dad-11d1-80b4-00c04fd430c8';

  get collectorUrl(): string {
    return this.baseUrl + '/track/PartnerDeeplinkClick?format=json&strict=true';
  }

  getAllGYGDeeplinks(): NodeListOf<HTMLAnchorElement> {
    return this.document.querySelectorAll('a[href*="getyourguide."]');
  }

  handleOnClick(event: Event) {
    const payloadV2 = this.getEventV2Payload(event);

    try {
      // send OpenApi event v2
      this.sendEventV2(payloadV2, EVENT_NAME);
    } catch (error) {
      // Silently fail
      console.error('PDCE ERROR', error);
    }
  }

  private getEventV2Payload(event: Event) {
    const href = this.getHREFFromEvent(event);
    const eventData: Partial<BaseWebEvent> & PartnerDeeplinkClickProperties = {
      customer_id: this.dataRepository.user.customer_id,
      partner_hash_code: this.dataRepository.header.partner_hash_code,
      partner_id: this.dataRepository.header.partner_id,
      page: this.dataRepository.page,
      deeplink_url: href,
      deeplink_id: uuidv5(href, PartnerDeeplinkClickEvent.NAMESPACE),
    };
    return eventData;
  }

  private getHREFFromEvent(event: Event) {
    const target = event.target as HTMLAnchorElement;
    return removeDeeplinkQueryString(target.href);
  }

  trackDeeplink(
    link: HTMLAnchorElement,
    onClickHandler: (this: HTMLAnchorElement, ev: MouseEvent) => any
  ) {
    link.target = '_blank';
    link.addEventListener('click', onClickHandler);
  }

  start() {
    this.getAllGYGDeeplinks().forEach((link: HTMLAnchorElement) =>
      this.trackDeeplink(link, this.handleOnClick.bind(this))
    );
  }
}
