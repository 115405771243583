import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants';

class TiqetsDeeplinksScraper extends Scraper {
  static TIQETS_LINK_QUERY = `a[href*="www.tiqets.com"]`;

  constructor() {
    super(
      'TIQETS-Deeplinks',
      TiqetsDeeplinksScraper.TIQETS_LINK_QUERY,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const deeplink = {
        name: COMPETITOR_NAMES.TIQETS,
        url: item.getAttribute('href'),
        text: item.textContent?.trim(),
      } as CompetitorDeeplinks;

      payload.competitor_deeplinks = payload.competitor_deeplinks || [];
      payload.competitor_deeplinks.push(deeplink);
    });
    return payload;
  }
}

export default TiqetsDeeplinksScraper;
