import PartnerUIEvent from '../events/PartnerUIEvent';
import { listenForWindowPostMessages } from '../helpers/listen';

// PartnerEvent<Partial<UIClick>>> is used in here to indicate that
// only some of the properties of UIClick will be sent.
// In the context of this event we're not sending a mobile_header, view_id etc.
// These properties are marked as optional in the IDL, but typescript generator
// doesn't recognize that and marks them as required which is incorrect.
export default class UIClickEvent extends PartnerUIEvent<Partial<UIClick>> {
  protected eventType: string = 'UIClick';

  get collectorUrl(): string {
    return this.baseUrl + `/track/${this.eventType}?format=json&strict=true`;
  }

  sendEventCallback(event: {
    messageType: string;
    eventType: string;
    eventData: UIClick;
  }) {
    // Thrift definition: https://github.com/getyourguide/analytics-pipeline/blob/master/idl/github.com/getyourguide/analytics-pipeline/UIClick.thrift
    this.sendEvent(event.eventType, event.eventData);
  }

  start(): void {
    listenForWindowPostMessages(
      this.eventType,
      this.sendEventCallback.bind(this)
    );
  }
}
