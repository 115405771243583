import Scraper from '../Scraper';
import { updateQueryStringParameter } from '../../../helpers/url';
import { v5 as uuidv5 } from 'uuid';
import { setLocalStorage } from '../../../helpers/localStorage';

declare global {
  interface Window {
    FIX: any;
  }
}

class GYGDeeplinksScraper extends Scraper {
  static GYG_LINK_QUERY = 'a[href*="getyourguide."]';
  static NAMESPACE = '6ba7b811-9dad-11d1-80b4-00c04fd430c8';

  constructor() {
    super(
      'GYG-Deeplinks',
      GYGDeeplinksScraper.GYG_LINK_QUERY,
      window._GYG.debug
    );
  }

  getBoundingClientRect(element: Element): any {
    const { width, height, x, y } = element.getBoundingClientRect();

    return {
      x: x !== undefined ? Math.round(x) : undefined,
      y: y !== undefined ? Math.round(y) : undefined,
      width: width !== undefined ? Math.round(width) : undefined,
      height: height !== undefined ? Math.round(height) : undefined,
    };
  }

  /**
   * Filter out affiliate network ID from deep-link campaigns in URL
   * @see https://getyourguide.atlassian.net/browse/PTECH-1564
   */
  cleanUrl(url: URL) {
    let cleanUrl = url;
    if (cleanUrl && cleanUrl.searchParams.has('cmp')) {
      const cmp = cleanUrl.searchParams.get('cmp')?.split('_amcid')[0];
      cmp && cleanUrl.searchParams.set('cmp', cmp);
    }
    return cleanUrl.toString();
  }

  /**
   * The CSS selector is matching all the GYG links, no matter their position.
   * This function makes sure that we are matching only links that are directly
   * pointing to our domain.
   *
   * @see https://getyourguide.atlassian.net/browse/PTECH-1894
   * @param item
   * @returns
   */
  private isDirectGYGLink(item: Element) {
    const href = item.getAttribute('href') || '';
    const gygLinkRegex = /^https?:\/\/(www\.)?getyourguide/;
    return gygLinkRegex.test(href);
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.filter(this.isDirectGYGLink).forEach((item: Element) => {
      const dimensions = this.getBoundingClientRect(item);

      const href = item.getAttribute('href') || '';
      const url = new URL(href);
      const cleanUrl = this.cleanUrl(url);
      const partnerId = url.searchParams.get('partner_id');
      if (partnerId) {
        setLocalStorage('partner_id', partnerId);
      }

      const deeplink_id = uuidv5(cleanUrl, GYGDeeplinksScraper.NAMESPACE);
      const deeplink = {
        ...dimensions,
        url: cleanUrl,
        text: item.textContent?.trim(),
        deeplink_id,
      } as DeeplinkAttributes;

      this.attachId(deeplink_id, item);
      payload.deeplinks = payload.deeplinks || [];
      payload.deeplinks.push(deeplink);
    });
  }

  private attachId(deeplink_id: string, element: Element) {
    const href = element.getAttribute('href') || '';
    if (!href || !deeplink_id) {
      return;
    }

    // Special case to handle SBI (Wordpress plugin) link redirection
    // See https://getyourguide.atlassian.net/browse/PTECH-1578
    if (window.hasOwnProperty('FIX') && window.FIX.hasOwnProperty('track'))
      return;

    const url = updateQueryStringParameter('deeplink_id', deeplink_id, href);
    element.setAttribute('href', url.toString());
  }
}

export default GYGDeeplinksScraper;
