import Task from '../Task';

class PageTask extends Task {
  async start({ payload }: { payload: DataRepository }): Promise<any> {
    const { offsetWidth, scrollHeight } = document.body;
    const {
      navigationStart,
      unloadEventStart,
      unloadEventEnd,
      redirectStart,
      redirectEnd,
      fetchStart,
      domainLookupStart,
      domainLookupEnd,
      connectStart,
      connectEnd,
      secureConnectionStart,
      requestStart,
      responseStart,
      responseEnd,
      domLoading,
      domInteractive,
      domContentLoadedEventStart,
      domContentLoadedEventEnd,
      domComplete,
      loadEventStart,
      loadEventEnd,
    } = performance.timing;

    payload.page = {
      width: offsetWidth,
      height: scrollHeight,
      page_title: document.title,
      performance: {
        navigation_start: navigationStart,
        unload_event_start: unloadEventStart,
        unload_event_end: unloadEventEnd,
        redirect_start: redirectStart,
        redirect_end: redirectEnd,
        fetch_start: fetchStart,
        domain_lookup_start: domainLookupStart,
        domain_lookup_end: domainLookupEnd,
        connect_start: connectStart,
        connect_end: connectEnd,
        secure_connection_start: secureConnectionStart,
        request_start: requestStart,
        response_start: responseStart,
        response_end: responseEnd,
        dom_loading: domLoading,
        dom_interactive: domInteractive,
        dom_content_loaded_event_start: domContentLoadedEventStart,
        dom_content_loaded_event_end: domContentLoadedEventEnd,
        dom_complete: domComplete,
        load_event_start: loadEventStart,
        load_event_end: loadEventEnd,
      } as PagePerformance,
    } as Page;

    return payload;
  }
}

export default PageTask;
