/**
 * Logs to the console (INFO) with a structured format
 * @param args list of arguments to be logged
 */
export const log = (debug: boolean, message: string, ...args: any[]) => {
  debug &&
    console.info(
      `%c[PA][${message}]`,
      'color: orange; font-weight: bold;',
      ...args
    );
};
