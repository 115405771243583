import Task from '../Task';
import { log } from '../../helpers/log';

abstract class Scraper extends Task {
  name: string;
  debug: boolean;
  querySelector: string;

  constructor(name: string, querySelector: string, debug: boolean) {
    super();

    this.name = name;
    this.debug = debug;
    this.querySelector = querySelector;
  }

  scrape(document: Document): Element[] {
    const items = Array.from(document.querySelectorAll(this.querySelector));
    return items.filter(item => !item.getAttribute('data-gyg-scraped'));
  }

  /**
   * Add a data attribute to all the elements that have been already scraped
   * the value of the data attribute is the epoch at which the element was scraped.
   * @param scrapedItems
   */
  markElements(scrapedItems: Element[]) {
    scrapedItems.forEach(item => {
      item.setAttribute('data-gyg-scraped', Date.now().toString());
    });
  }

  highlightElements(scrapedItems: Element[]) {
    this.debug &&
      scrapedItems.forEach(item => {
        (item as HTMLElement).style.border = '10px solid #39ff14';
      });
  }

  abstract processElements(
    scrapedItems: Element[],
    payload: DataRepository
  ): any;

  /**
   * Main method to start the scraper.
   * Parses the page and process its elements.
   *
   * @param document
   */
  async start({
    document,
    payload,
  }: {
    document: Document;
    payload: DataRepository;
  }): Promise<any> {
    const scrapedItems = this.scrape(document);
    this.markElements(scrapedItems);
    this.highlightElements(scrapedItems);
    this.processElements(scrapedItems, payload);

    log(
      this.debug,
      this.name,
      this.querySelector,
      `items found: ${scrapedItems.length}`,
      scrapedItems
    );

    return payload;
  }
}

export default Scraper;
