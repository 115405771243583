import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebLinkVisible } from '../../openapi/analytics-events/model/ticketing-web-link-visible';

const EVENT_NAME: string = 'TicketingWebLinkVisible';

export default class TicketingWebLinkVisibleEvent extends PartnerEvent<
  TicketingWebLinkVisible
> {
  static NAMESPACE = '1ac966b7-eba7-4c83-b142-e738da02bf30'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebLinkVisible }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
