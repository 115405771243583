import { BLACKLISTED_PATTERNS } from '../constants';

/**
 * Verify that the given URL is not part of the blacklist
 * @param url
 */
export function isURLallowed(url: string): boolean {
  let violations = 0;

  BLACKLISTED_PATTERNS.forEach(blacklistedPattern => {
    if (blacklistedPattern.test(url)) violations++;
  });

  return violations === 0;
}

/**
 * Updates query parameter in the URL string.
 *
 * Url.searchParams is not working in IE
 * {@link https://developer.mozilla.org/en-US/docs/Web/API/URL/searchParams}
 */
export function updateQueryStringParameter(
  key: string,
  value: string,
  uri: string
) {
  const re = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
  const separator = uri.indexOf('?') !== -1 ? '&' : '?';
  if (uri.match(re)) {
    return uri.replace(re, '$1' + key + '=' + value + '$2');
  } else {
    return uri + separator + key + '=' + value;
  }
}

export function removeDeeplinkQueryString(href: string) {
  const pattern = 'deeplink_id=[\\w-]+';
  return href.replace(new RegExp(`${pattern}&|&${pattern}|${pattern}`), '');
}

export function load(
  url: string,
  onload?: (this: GlobalEventHandlers, ev: Event) => any
) {
  const head = document.getElementsByTagName('head')[0];

  const script = document.createElement('script');
  script.async = true;
  script.src = url;
  if (onload) script.onload = onload;
  head.appendChild(script);
}
