import Task from '../Task';
import { v4 as uuidv4 } from 'uuid';

class EventPropertiesTask extends Task {
  static SENT_BY = 'PartnerAnalyzer';

  async start({ payload }: { payload: DataRepository }): Promise<any> {
    payload.event_properties = {
      // event_name -> needs to be filled by the varuous events
      uuid: uuidv4(),
      sent_by: EventPropertiesTask.SENT_BY,
    } as EventProperties;

    return payload;
  }
}

export default EventPropertiesTask;
