import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class ViatorWidgetsScraper extends Scraper {
  static VIATOR_WIDGET = `iframe[src*="www.viator.com/orion/widget"]`;

  constructor() {
    super(
      'Viator-CompetitorWidgets',
      ViatorWidgetsScraper.VIATOR_WIDGET,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const widget = {
        name: COMPETITOR_NAMES.VIATOR,
        url: item.getAttribute('src'),
      } as CompetitorWidgets;

      payload.competitor_widgets = payload.competitor_widgets || [];
      payload.competitor_widgets.push(widget);
    });
    return payload;
  }
}

export default ViatorWidgetsScraper;
