import partnerEvents from './events';
import {
  setLocalStorage,
  removeLocalStorage,
  getLocalStorage,
} from './helpers/localStorage';

class PartnerAnalyzer {
  // This data is used across different events and it
  // acts as a single source of truth.
  dataRepository = {
    container_name: 'partner-analyzer',
    version: '__GITCOMMIT__',
  } as DataRepository;

  constructor() {
    // Setup global
    window._GYG = window._GYG || {};

    const debugKey = 'pa_debug';
    const url = new URL(window.location.href);
    const pa_debug = url.searchParams.get(debugKey);

    if (pa_debug === '1') {
      setLocalStorage(debugKey, pa_debug);
    } else if (pa_debug === '0') {
      removeLocalStorage(debugKey);
    }

    window._GYG.debug = getLocalStorage(debugKey) === '1';

    partnerEvents.forEach(PartnerEvent => {
      const currentPartnerEvent = new PartnerEvent(
        this.dataRepository,
        document
      );
      currentPartnerEvent.start();
    });
  }
}

new PartnerAnalyzer();
