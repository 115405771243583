import Task from '../Task';
import { setLocalStorage, getLocalStorage } from '../../helpers/localStorage';

class HeaderTask extends Task {
  getOS(): String {
    let OSName = 'Unknown OS';
    if (navigator.appVersion.indexOf('Win') !== -1) OSName = 'Windows';
    if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS';
    if (navigator.appVersion.indexOf('X11') !== -1) OSName = 'UNIX';
    if (navigator.appVersion.indexOf('Linux') !== -1) OSName = 'Linux';
    return OSName;
  }

  /**
   * getPartnerHashCode
   * We find the embedded script tag and retreive the partner id
   */
  getPartnerHashCode(): String | null | undefined {
    let partnerHashCode = null;
    const script = document.querySelector(
      'script[src*="getyourguide.com/dist/pa."]'
    );

    if (script) {
      partnerHashCode = script.getAttribute('data-gyg-partner-id');
      if (!!partnerHashCode) setLocalStorage('partner_id', partnerHashCode);
    }
    if (!partnerHashCode) {
      partnerHashCode = getLocalStorage('partner_id');
    }
    return partnerHashCode?.toUpperCase();
  }

  isMobileDevice(): Boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
      navigator.userAgent
    );
  }

  getPlatform(): String {
    return this.isMobileDevice() ? 'mweb' : 'desktop';
  }

  async start({ payload }: { payload: DataRepository }): Promise<any> {
    const { userAgent = null, language = null } = navigator;
    const { referrer = null } = document;
    const { href = null, origin = null } = window.location;

    payload.header = {
      platform: this.getPlatform(),
      domain: origin,
      url: href,
      user_agent: userAgent,
      referrer_domain: referrer,
      os: this.getOS(),
      partner_hash_code: this.getPartnerHashCode(),
      accept_language: [language],
    } as Header;

    return payload;
  }
}

export default HeaderTask;
