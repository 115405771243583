import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class TicketbarWidgetsScraper extends Scraper {
  static TICKETBAR_WIDGET = `div[class*="Ticketbar-Widget"]`;

  constructor() {
    super(
      'Ticketbar-CompetitorWidgets',
      TicketbarWidgetsScraper.TICKETBAR_WIDGET,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(() => {
      const widget = {
        name: COMPETITOR_NAMES.TICKETBAR,
        // Not an iframe, dom is generated clientside
        url: '',
      } as CompetitorWidgets;

      payload.competitor_widgets = payload.competitor_widgets || [];
      payload.competitor_widgets.push(widget);
    });
    return payload;
  }
}

export default TicketbarWidgetsScraper;
