import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebAddToCartSuccess } from '../../openapi/analytics-events/model/ticketing-web-add-to-cart-success';

const EVENT_NAME: string = 'TicketingWebAddToCartSuccess';

export default class TicketingWebAddToCartSuccessEvent extends PartnerEvent<
  TicketingWebAddToCartSuccess
> {
  static NAMESPACE = 'c9a6bfce-16d7-452e-b463-2872065684c3'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebAddToCartSuccess }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
