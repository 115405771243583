import Task from '../Task';
import { v4 as uuidv4 } from 'uuid';
import { get, set } from 'es-cookie';
import { VISITOR_ID_SESSION_STORAGE_KEY } from '../../constants';
import 'whatwg-fetch';
import { WIDGET_BASE_URL } from '../../constants';

// This function is duplicated in widgets/client-loader:
// https://github.com/getyourguide/partner-widgets/blob/9b188462634be149c323633e0e601e20dc548df9/packages/client-loader/src/utils/visitor-id-tracking-lock.ts
const setupVisitorPromise = (window: Window) => {
  if (!Object.prototype.hasOwnProperty.call(window, 'GYG')) {
    window.GYG = window.GYG || {};
  }
  if (!window.GYG.visitorIdPromise) {
    window.GYG.visitorIdPromise = fetch(`${WIDGET_BASE_URL}/_gnikcart`, {
      credentials: 'include',
    })
      .then(response => response.json())
      .catch(() => {
        // Do nothing as its likely the user has an ad blocker
        const visitorId = uuidv4()
          .replace(/-/g, '')
          .toUpperCase()
          .substring(0, 32);

        window.GYG.visitorIdPromise = Promise.resolve({
          visitor_id: visitorId,
        });
      });
  }
  return window.GYG.visitorIdPromise;
};

class UserTask extends Task {
  async getVisitorId() {
    // We rely on sessionStorage to reduce the load on the _gnikcart endpoint
    let localVisitorId =
      sessionStorage.getItem(VISITOR_ID_SESSION_STORAGE_KEY) || '';
    if (!localVisitorId) {
      // The _gnikcart endpoint (tracking spelled backwards) being called here has two purposes: on one hand it sets the visitor_id
      // cookie on the .getyourguide.com domain and on the other hand it returns the same value as part of the
      // response body so that it can be used on 3rd party domains.

      const { visitor_id } = await setupVisitorPromise(window);
      if (visitor_id) {
        sessionStorage.setItem(VISITOR_ID_SESSION_STORAGE_KEY, visitor_id);
      }
      localVisitorId = visitor_id;
    }

    return localVisitorId;
  }

  getSessionId(sessionId: string | null | undefined) {
    sessionId = sessionId || get('session_id');
    if (!sessionId) {
      sessionId = uuidv4();
      set('session_id', sessionId);
    }

    return sessionId;
  }

  async start({
    sessionId,
    payload,
  }: {
    sessionId: string;
    payload: DataRepository;
  }): Promise<any> {
    const { language = null } = navigator;

    payload.user = {
      visitor_id: await this.getVisitorId(),
      session_id: this.getSessionId(sessionId),
      locale_code: language,
    } as User;

    return payload;
  }
}
export default UserTask;
