import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebUIClick } from '../../openapi/analytics-events/model/ticketing-web-ui-click';

const EVENT_NAME: string = 'TicketingWebUIClick';

export default class TicketingWebUIClickEvent extends PartnerEvent<
  TicketingWebUIClick
> {
  static NAMESPACE = '7cde4402-cd54-4f28-a4aa-5eb96add2d88'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebUIClick }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
