abstract class Task {
  abstract start({
    document,
    payload,
    visitorId,
    sessionId,
  }: {
    payload: DataRepository;
    document?: Document;
    visitorId?: string;
    sessionId?: string;
  }): Promise<any>;
}

export default Task;
