import { COLLECTOR_BASE_URL } from '../constants';
import merge from 'deepmerge';
import 'whatwg-fetch';

abstract class PartnerUIEvent<T> {
  abstract readonly collectorUrl: string;
  document: Document;
  dataRepository: DataRepository;
  protected baseUrl: string;

  constructor(
    dataRepository: DataRepository,
    document: Document,
    baseUrl: string = COLLECTOR_BASE_URL
  ) {
    this.dataRepository = dataRepository;
    this.document = document;
    this.baseUrl = baseUrl;
  }

  abstract start(): void;

  sendEvent(eventName: string, eventData: Partial<T>): void {
    // Deeply merge what has been sent from partner-ticketing with the baseEvent created here
    const finalEventData = merge(this.getUIEventBase(), eventData) as
      | Partial<UIClick>
      | Partial<UIShow>
      | Partial<UIEnteredDisplayPort>;

    // Set proper event_name
    if (finalEventData && finalEventData.event_properties) {
      finalEventData.event_properties.event_name = eventName;
    }

    // Stringify metadata. Analytics pipeline is expecting a string here
    if (finalEventData && finalEventData.metadata) {
      finalEventData.metadata = JSON.stringify(finalEventData.metadata);
    }

    window.fetch(this.collectorUrl, {
      method: 'POST',
      headers: {
        'Content-type': 'text/plain',
      },
      body: JSON.stringify(finalEventData),
      keepalive: true,
    });
  }

  /**
   * Function that is used to construct UIClick, UIShow,... event's base.
   * The event data is later enriched with everything that is sent by the
   * consuming application (e.g. partner-ticketing)
   */
  getUIEventBase() {
    return {
      event_properties: {
        ...this.dataRepository.event_properties,
      },
      container_name: this.dataRepository.container_name,
      user: this.dataRepository.user,
      header: {
        ...this.dataRepository.header,
      },
    };
  }
}

export default PartnerUIEvent;
