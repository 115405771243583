import {
  BaseWebEvent,
  WebLoggerConfig,
  WebLogger,
} from '@getyourguide/analytics-events-js-sdk';

import { AnalyticsWebEventConfig } from 'events/logger/analytics-web-event-config';

export type PartialBaseWebEvent<T> = Partial<BaseWebEvent> & T;
export const logAnalyticsWebEvent = (
  config: AnalyticsWebEventConfig,
  eventData: Partial<BaseWebEvent>,
  eventName: string
) => {
  try {
    const webLoggerConfig: WebLoggerConfig = {
      isDebug: process.env.NODE_ENV !== 'production',
      collectorUrl: config.collector_url,
      sentBy: config.sent_by,
      applicationVersion: config.version,
      platform: config.platform,
      viewId: config.view_id,
      containerName: config.container_name,
      userAgent: config.userAgent,
      domain: config.domain,
      url: config.url,
      referrerUrl: config.referrer_url,
      visitorId: config.visitor_id,
    };

    const webLogger = new WebLogger(webLoggerConfig);

    // Let's not try to send the event if code is executed via a jest unit test
    if (process.env.NODE_ENV === 'test') return;

    webLogger.log(eventName, eventData);
  } catch (error) {
    // Silently fail
    console.error('AEL ERROR', error);
  }
};
