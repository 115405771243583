import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebNoAvailability } from '../../openapi/analytics-events/model/ticketing-web-no-availability';

const EVENT_NAME: string = 'TicketingWebNoAvailability';

export default class TicketingWebNoAvailabilityEvent extends PartnerEvent<
  TicketingWebNoAvailability
> {
  static NAMESPACE = '67e16cd6-4425-4b51-b1e3-beec495401d1'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebNoAvailability }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
