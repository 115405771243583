import { v5 as uuidv5 } from 'uuid';
const NAMESPACE = '0e7c8e4a-453a-4a3f-aa76-186f61d75001';

/**
 * Generate Widget ID from Url
 * @param  {String} widgetUrl request url
 * @return {String} Returns widgetId
 */

// until the partner analyzer is moved to the partner widgets monorepo
// all changes here need to be in sync with changes in the id-generation package in the monorepo
export const generateWidgetId = (widgetUrl: string): string => {
  const url = new URL(widgetUrl);

  const widgetSpec = url.pathname
    .replace(/\/$/, '')
    .split('/')
    .pop()!
    .split('.');

  // we currently define 5 types: [activities, city, availability, activities-auto, custom]
  let widgetType = widgetSpec[0];

  // ! fix partner-portal spelling mistake
  // see commit 968f36841fd929caf8229f5d67060ca3835b8727@partner-portal
  if (widgetType === 'activites') {
    widgetType = 'activities';
  }

  if (
    widgetType !== 'activities' &&
    widgetType !== 'city' &&
    widgetType !== 'availability' &&
    widgetType !== 'activities-auto' &&
    widgetType !== 'map'
  ) {
    widgetType = 'custom';
  }

  let widgetFormat = widgetSpec[1];

  // some partners use jpg and jpeg interchangeably
  if (widgetFormat === 'jpg') {
    widgetFormat = 'jpeg';
  }

  const widgetParams = {
    p: url.searchParams.has('partner_id')
      ? url.searchParams.get('partner_id')
      : url.pathname.split('/')[1],
    w: widgetType,
    f: widgetFormat,
    m: url.searchParams.has('cmp') ? url.searchParams.get('cmp') : undefined,
    q: url.searchParams.has('q') ? url.searchParams.get('q') : undefined,
    i: url.searchParams.has('iata') ? url.searchParams.get('iata') : undefined,
    d: url.searchParams.has('location_id')
      ? url.searchParams.get('location_id')
      : undefined,
    c: url.searchParams.has('currency')
      ? url.searchParams.get('currency')
      : undefined,
    l: url.searchParams.has('locale_code')
      ? url.searchParams.get('locale_code')
      : undefined,
    n: url.searchParams.has('number_of_items')
      ? Number(url.searchParams.get('number_of_items'))
      : undefined,
    t: url.searchParams.has('tour_ids')
      ? url.searchParams.get('tour_ids')
      : undefined,
    u: url.searchParams.has('tour_id')
      ? url.searchParams.get('tour_id')
      : undefined,
    h: url.searchParams.has('theme')
      ? url.searchParams.get('theme')
      : undefined,
    v: url.searchParams.has('variant')
      ? url.searchParams.get('variant')
      : undefined,
    e: url.searchParams.has('excluded_tour_ids')
      ? url.searchParams.get('excluded_tour_ids')
      : undefined,
    a: url.searchParams.has('lat') ? url.searchParams.get('lat') : undefined,
    o: url.searchParams.has('lon') ? url.searchParams.get('lon') : undefined,
  };
  return uuidv5(JSON.stringify(widgetParams), NAMESPACE);
};
