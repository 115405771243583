import Scraper from '../Scraper';
import { COMPETITOR_NAMES } from '../../../constants/index';

class MediavineWidgetScraper extends Scraper {
  static MEDIAVINE_WIDGET = `div[class*="mv-ad-box"]`;

  constructor() {
    super(
      'Mediavine-CompetitorWidgets',
      MediavineWidgetScraper.MEDIAVINE_WIDGET,
      window._GYG.debug
    );
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const mediavineSlotId = item.getAttribute('data-slotid');
      const url = `${document.baseURI}#slot=${mediavineSlotId}`;
      const widget: CompetitorWidgets = {
        name: COMPETITOR_NAMES.MEDIAVINE,
        url,
      };

      payload.competitor_widgets = payload.competitor_widgets || [];
      payload.competitor_widgets.push(widget);
    });
    return payload;
  }
}

export default MediavineWidgetScraper;
