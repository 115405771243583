import GYGDeeplinksScraper from './GYGDeeplinksScraper';
import ViatorDeeplinksScraper from './ViatorDeeplinksScraper';
import TicketbarDeeplinksScraper from './TicketbarDeeplinksScraper';
import MusementDeeplinksScraper from './MusementDeeplinksScraper';
import CivitatisDeeplinksScraper from './CivitatisDeeplinksScraper';
import TiqetsDeeplinksScraper from './TiqetsDeeplinksScraper';
import KlookDeeplinksScraper from './KlookDeeplinksScraper';

export default [
  GYGDeeplinksScraper,
  ViatorDeeplinksScraper,
  TicketbarDeeplinksScraper,
  MusementDeeplinksScraper,
  CivitatisDeeplinksScraper,
  TiqetsDeeplinksScraper,
  KlookDeeplinksScraper,
];
