/**
 * Helper function that adds a window listener that waits for
 * window.postMessage events that have messageType set to event_logger
 * The function is used
 * @param eventTypeToListenFor
 * @param callbackFn
 */
export function listenForWindowPostMessages(
  eventTypeToListenFor: string,
  callbackFn: Function
) {
  window.addEventListener('message', event => {
    const data = event?.data;
    try {
      if (data && data.messageType === 'event_logger') {
        const { eventType } = data;
        if (
          !eventType ||
          eventType.toLowerCase() !== eventTypeToListenFor.toLowerCase()
        ) {
          return;
        }

        callbackFn(data);
      }
    } catch (error) {
      // Silently fail
      console.error(
        `Failed to send ${eventTypeToListenFor} with Partner Analyzer`
      );
    }
  });
}
