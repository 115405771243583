import Scraper from '../Scraper';
import { generateWidgetId } from '../../../helpers/generateWidgetId';
import { setLocalStorage } from '../../../helpers/localStorage';

type DeepNonArrayNullable<T> = T extends Array<any>
  ? T
  : { [k in keyof T]: DeepNonArrayNullable<T[k]> | null };

// thrift to TS compiler is broken and it does not mark optional fields properly
type ThriftFixedWidgetAttributes = DeepNonArrayNullable<WidgetAttributes>;

const WIDGET_CREATED_ATTRIBUTE = 'data-gyg-widget-created';
const WIDGET_RENDERED_ATTRIBUTE = 'data-gyg-widget-rendered';

class GYGWidgetScraper extends Scraper {
  static GYG_WIDGET_QUERY =
    'iframe[src^="https://widget.getyourguide.com"][data-gyg-widget-rendered]';

  constructor() {
    super('GYG-Widget', GYGWidgetScraper.GYG_WIDGET_QUERY, window._GYG.debug);
  }

  extractIds(value: string | null): number[] | null {
    if (!value) return null;

    const result = value
      .split(',')
      .map(x => parseInt(x))
      .filter(x => !Number.isNaN(x));

    return result;
  }

  parseNumber(value: string | null) {
    if (value) return parseInt(value);

    return null;
  }

  parseFloat(value: string | null) {
    if (value) return parseFloat(value);

    return null;
  }

  getBoundingClientRect(element: Element) {
    const { width, height, x, y } = element.getBoundingClientRect();

    return {
      x: x !== undefined ? Math.round(x) : undefined,
      y: y !== undefined ? Math.round(y) : undefined,
      width: width !== undefined ? Math.round(width) : undefined,
      height: height !== undefined ? Math.round(height) : undefined,
    };
  }

  getWidgetType(url: URL): string {
    let widgetType = url.pathname.split('/')[2].split('.')[0];
    // Fix for spelling mistake from portal
    if (widgetType === 'activites') widgetType = 'activities';

    if (
      widgetType !== 'activities' &&
      widgetType !== 'city' &&
      widgetType !== 'availability' &&
      widgetType !== 'activities-auto' &&
      widgetType !== 'map'
    ) {
      widgetType = 'custom';
    }

    return widgetType;
  }

  getWidgetFormat(url: URL): string {
    let widgetSpec = url.pathname.split('/')[2].split('.');

    let widgetFormat = 'frame';
    if (widgetSpec.length > 1) {
      widgetFormat = widgetSpec[1];
      // some partners use jpg and jpeg interchangeably
      if (widgetFormat === 'jpg') {
        widgetFormat = 'jpeg';
      }
    }

    return widgetFormat;
  }

  getWidgetRenderingTime(item: Element): number | undefined {
    const parentEl = item.parentElement;

    if (!parentEl) {
      return undefined;
    }

    // WIDGET_RENDERED_ATTRIBUTE is set on the iframe
    const renderedAt = item.getAttribute(WIDGET_RENDERED_ATTRIBUTE);

    // WIDGET_CREATED_ATTRIBUTE is set on a wrapping div
    const createdAt = parentEl.getAttribute(WIDGET_CREATED_ATTRIBUTE);

    if (!renderedAt || !createdAt) {
      return undefined;
    }

    return parseInt(renderedAt, 10) - parseInt(createdAt, 10);
  }

  processElements(scrapedItems: Element[], payload: DataRepository) {
    scrapedItems.forEach(item => {
      const dimensions = this.getBoundingClientRect(item);
      const url = item.getAttribute('src');
      if (!url) return;
      const parsedUrl = new URL(url);
      const partnerId = parsedUrl.searchParams.get('partner_id');
      const widget: ThriftFixedWidgetAttributes = {
        ...dimensions,
        type: this.getWidgetType(parsedUrl),
        rendering_time: this.getWidgetRenderingTime(item),
        // @ts-expect-error: version, release_version attributes are missing
        widget_parameters: {
          campaign: parsedUrl.searchParams.get('cmp'),
          currency: parsedUrl.searchParams.get('currency'),
          excluded_tour_ids: this.extractIds(
            parsedUrl.searchParams.get('excluded_tour_ids')
          ),
          iata: parsedUrl.searchParams.get('iata'),
          locale_code: parsedUrl.searchParams.get('locale_code'),
          number_of_items: this.parseNumber(
            parsedUrl.searchParams.get('number_of_items')
          ),
          partner_id: partnerId,
          query: parsedUrl.searchParams.get('q'),
          tour_ids: this.getTourIdsFromSearchParams(parsedUrl.searchParams),
          widget_id: generateWidgetId(url),
          geo_location: {
            latitude: this.parseFloat(parsedUrl.searchParams.get('lat')),
            longitude: this.parseFloat(parsedUrl.searchParams.get('lon')),
          },
          format: this.getWidgetFormat(parsedUrl),
        },
      };

      if (partnerId) {
        setLocalStorage('partner_id', partnerId);
      }

      payload.widgets = payload.widgets || [];

      // @ts-expect-error: We pass ThriftFixedWidgetAttributes instead of WidgetAttributes
      payload.widgets.push(widget);
    });
    return payload;
  }

  getTourIdsFromSearchParams(searchParams: URLSearchParams): number[] | null {
    const extractedIds = this.extractIds(searchParams.get('tour_ids'));
    if (!extractedIds) {
      const extractedIdFromAvailabilityWidgetIfExists = this.parseNumber(
        searchParams.get('tour_id')
      );

      return extractedIdFromAvailabilityWidgetIfExists
        ? [extractedIdFromAvailabilityWidgetIfExists]
        : null;
    }
    return extractedIds;
  }
}

export default GYGWidgetScraper;
