import Task from '../Task';
import { WIDGET_BASE_URL } from '../../constants';

class ScriptSourceTask extends Task {
  /**
   * If a Partner Analyzer script tag contains the 'data-gyg-script-origin=widget'
   * that means it was loaded indirectly using the widget script
   * @param element
   */
  isScriptLoadedViaWidget(element: HTMLScriptElement) {
    const origin = element.dataset.gygScriptOrigin;
    return origin === 'widget';
  }

  getScriptOriginType(scripts: HTMLScriptElement[]): String {
    const loadedViaWidget = scripts.some(this.isScriptLoadedViaWidget);
    const loadedDirectly = !scripts.every(this.isScriptLoadedViaWidget);

    if (loadedViaWidget && loadedDirectly) {
      return 'BOTH';
    } else if (loadedViaWidget) {
      return 'WIDGET';
    }

    return 'DIRECT';
  }

  getScripts() {
    const analyzerUrl =
      window._GYG.env === 'production'
        ? `${WIDGET_BASE_URL}/dist/pa.umd.production.min.js`
        : 'dist/pa.umd.development.js';

    return Array.from(
      document.querySelectorAll<HTMLScriptElement>(
        `script[src*="${analyzerUrl}"]`
      )
    );
  }

  async start({ payload }: { payload: DataRepository }): Promise<any> {
    const script_origin = this.getScriptOriginType(this.getScripts());
    payload.script_origin = (script_origin as unknown) as PartnerAnalyzerOriginType;
    return payload;
  }
}
export default ScriptSourceTask;
