import PartnerEvent from './PartnerEvent';
import { listenForWindowPostMessages } from 'helpers/listen';

// @ts-ignore
import { TicketingWebPageView } from '../../openapi/analytics-events/model/ticketing-web-page-view';

const EVENT_NAME: string = 'TicketingWebPageView';

export default class TicketingWebPageViewEvent extends PartnerEvent<
  TicketingWebPageView
> {
  static NAMESPACE = '7dde5df8-7dba-4469-ba5a-a800de137523'; // Random uuid v4 string

  get collectorUrl(): string {
    return ''; // v2 events use global collectorUrl, this property is not needed, but TS complains otherwise
  }

  sendEventCallback(event: { eventData: TicketingWebPageView }) {
    this.sendEventV2(event.eventData, EVENT_NAME);
  }

  start() {
    listenForWindowPostMessages(EVENT_NAME, this.sendEventCallback.bind(this));
  }
}
